/* styles.css */
.lpcv-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 576px) {
    align-items: flex-start;
  }

  @media (min-width: 1200px) {
    width: 240px;
    .container-fluid {
      padding: 0;
    }
  }
}

@media (min-width: 1024px) {
  .lpcv-sidebar {
    align-items: flex-start;
  }
}

.lpcv-container {
  width: 320px;

  @media (min-width: 576px) {
    width: 100%;
  }

  @media (min-width: 992px) {
    width: 900px;
  }
}
.text-end.text-white {
  list-style-position: inside; /* Set the marker inside the list item */
  padding-left: 0; /* Remove default left padding */
}

.text-end p {
  margin-bottom: 0;
}

.text-end.text-white li {
  margin-bottom: 5px; /* Adjust margin between list items if needed */
}

.text-3xl {
  font-size: 1.875rem; /* Adjust this value as needed */
  line-height: 2.25rem; /* Adjust this value as needed */
}

.custom-line-height {
  line-height: 1.75rem;
}
