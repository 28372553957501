/* Home Page Start*/
.blog_title {
    color: #144da1;
    font-size: 22px;
    font-weight: bold;
  }
.card_size {
    border-radius: 6px;
    background: #fff;
    box-shadow: 0.1px 0.1px 1px 1px rgba(0, 0, 0, 0.1);
    height: 335px;
  }
  .card_size h6 {
    color: #090909;
    font-family: Noto Sans CJK JP;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: color 0.3s;
  }
  .card_size h6:hover {
    color: #6dc5fe;
    cursor: pointer;
    font-weight: 600;
  }
  .card_text {
    color: #000;
    font-family: Noto Sans CJK JP;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .card-image-container {
    position: relative;
    overflow: hidden;
    border-radius: 6px;
  }
  .card_image {
    object-fit: cover;
    height: 150px;
    width: 100%;
    transition: transform 0.8s;
    cursor: pointer;
  }
  .card_image:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    opacity: 0.9;
  }
  .card_date_time {
    color: #5b5b5b;
    font-family: Noto Sans CJK JP;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .banner-container {
    position: relative;
    overflow: hidden;
  }
  .banner-image {
    cursor: pointer;
    object-fit: cover;
    width: 100%;
    transition: transform 0.8s;
  }
  .banner-image img {
    display: block;
    transition: transform 0.8s;
    width: 100%;
  }
  .banner-image:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    opacity: 0.9;
  }
  .dark-blue-button {
    border-radius: 5px !important;
    background: #23303f !important;
    color: #ffffff !important;
  }
  .dark-blue-button:hover {
    border-radius: 5px !important;
    background: #384a5f !important;
    color: #ffffff !important;
  }
  .blog_list_h4 {
    color: #000;
    font-family: Noto Sans CJK JP;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .list-group-item  {
    background-color: #f5f5f3cc !important;
  }