@media only screen and (max-width: 767px) {
    .footer-pa-logo {
      padding: 8px 26px !important;
    }
    .footer-logo {
      margin: 0px !important;
    }
  
    .copy-right {
      text-align: start !important;
    }
  
    .footer-colr-plogo {
      margin-left: 0 !important;
    }
  
    .border-bottom1 {
      border-bottom: 1px solid #707070;
      display: block;
    }
  }
  