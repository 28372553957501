.main-header {
  height: auto;
  background-color: #f5f5f3cc;
  box-shadow: 0px 1px 0px #00000029;
  width: 100%;
  /* position: fixed; */
  z-index: 99;
}

.search-headr {
  height: 40px;
  border-radius: 4px !important;
  font-size: 14px !important;
  border-right: none !important;
}

.search-btn {
  background-color: #fff !important;
  color: #656565;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  width: 60px !important;
  text-align: center;
  padding-left: 20px;
}

.headr-nav {
  margin: auto;
}

.headr-nav a {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}
