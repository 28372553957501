.custom-px {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
@media only screen and (max-width: 575px) {
  .custom-px {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
.list-group-item {
  background-color: #f5f5f3cc !important;
}
