.trending-article-container {
  overflow: hidden; /* Hide overflowing content */
}

.slick-slide {
  padding: 5px !important ;
}

.trending-article {
  object-fit: cover;
  margin: 0 auto; /* Center the image horizontally */
  transition: transform 0.8s;
  overflow: hidden; /* Hide overflowing content */
  height: 300px;
  width: 100%;
}

.trending-article img {
  height: 100%;
}

.trending-article:hover {
  transform: scale(1.1); /* Increase the scale on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow on hover */
  opacity: 0.9;
}

h6 {
  cursor: pointer;
}

h6:hover {
  color: #6dc5fe;
  font-weight: 600;
}

.trending-article_card_date_time {
  color: #5b5b5b;
  font-family: Noto Sans CJK JP;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}