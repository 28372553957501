body {
  font-size: 14px;
  background-color: #fff;
  font-family: "Noto Sans CJK JP";
  scroll-behavior: smooth;
  place-self: end;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f5f5f3cc;
}

@font-face {
  font-family: "Noto Sans CJK JP";
  src: url(./fonts/NotoSansJP-Regular.otf);
}

@font-face {
  font-family: "Noto Sans CJK JP bold";
  src: url(./fonts/NotoSansJP-Bold.otf);
}

@font-face {
  font-family: "Noto Sans CJK JP medium";
  src: url(./fonts/NotoSansJP-Medium.otf);
}

#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 25px;
  border: none;
  outline: none;
  color: #fff;
  background: #ffa229;
  cursor: pointer;
  padding: 15px;
  border-radius: 50% !important;
  transition: 0.3s ease-out;
  width: 80px;
  height: 80px;
}

#myBtn:hover {
  background-color: #ffa229;
}

p {
  font-size: 14px;
  font-weight: normal;
  color: #656565;
}

h3 {
  font-size: 50px;
  font-weight: bolder;
  font-family: "Noto Sans CJK JP bold";
}

h4 {
  font-size: 30px;
  font-weight: bolder;
  font-family: "Noto Sans CJK JP bold";
}

h5 {
  font-size: 22px;
  font-weight: bold;
  font-family: "Noto Sans CJK JP bold";
}

.padding-body {
  padding-left: 80px;
  padding-right: 80px;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0em;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent;
  border-radius: 6px;
}

.login-icon {
  font-size: 20px;
}

.login-btn {
  width: 200px;
  font-size: 14px;
  border-radius: 33px;
  height: 53px;
  color: #00cc99;
  background: #fff;
  border: 1px solid #00cc99;
}

.login-btn:hover {
  color: #fff;
  background: #00cc99;
  border: 1px solid #00cc99;
}

.margin-btn {
  margin-top: -7px;
}

.counseling-btn {
  width: 295px;
  font-size: 14px;
  border-radius: 33px;
  height: 53px;
  color: #fff;
  background: #ffa229;
  border: 1px solid #ffa229;
}

.counseling-btn:hover {
  color: #ffa229;
  background: #fff;
  border: 1px solid #ffa229;
}

.login-header {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 8rem;
}

.dob-inp {
  position: relative;
}

.calender-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #888888;
  font-size: 18px;
}

.bg-jobc {
  background-color: #f9f9f9;
}

/******footer start****/
.footer-bg {
  background: #333333;
  width: 100%;
  height: auto;
  position: static;
  bottom: 0;
  left: 0;
  margin: auto;
}

.nav-footer {
  color: #fff8f8;
}

.nav-footer:hover {
  color: #00cc99;
}

.border-left {
  border-left: 1px solid #707070;
}

.social {
  font-size: 20px;
  color: #fff8f8;
}

.social-list {
  margin-top: 20px;
  display: flex;
}

.social-list li {
  background-color: #00cc99;
  color: #fff8f8;
  width: 67px;
  height: 57px;
  border-radius: 10px;
  font-size: 30px;
  margin-right: 15px;
  list-style: none;
  text-align: center;
}

.social-list li i {
  padding-top: 15px;
}

.border-bottom1 {
  display: none;
}

.social-list li:hover {
  background-color: #ffa229;
  cursor: pointer;
}

.footer-bottom {
  background-color: #232323;
  padding: 25px 0px;
  bottom: 0;
}

.copy-right {
  color: #636363;
}

/***top page****/

/* CSS */
.top-banner {
  width: 100%;
  height: 580px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  /* background-image: url(./Images/Banner-image.svg); */
}

.card-title:hover {
  cursor: pointer;
  color: rgb(
    59 130 246/0.5
  ) !important; /* Change this to your desired hover color */
  text-decoration: none; /* Ensure text-decoration is none on hover */
}

.banner-top h3 {
  font-size: 45px;
  font-weight: bold;
  color: #fff;
  padding-top: 15rem;
}

.top-paragrm {
  color: #ffffff !important;
}

.top-button {
  background-color: #00cc99;
  border-radius: 45px;
  border: 6px solid #fff;
  height: 70px;
  width: 250px;
  color: #fff;
  font-weight: bolder;
  box-shadow: 0px 3px 6px #00000029;
}

.top-button:hover {
  background: #02c090;
}

/***company section start***/

.main-heading {
  color: #393939;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main-heading h4::after {
  content: "";
  position: absolute;
  display: block;
  width: 82px;
  height: 3px;
  background: #00cc99;
  bottom: -6px;
  left: calc(50% - 55px);
}

.main-heading h4::before {
  content: "";
  display: block;
}

.Card-box1 {
  box-shadow: 0px 3px 5px #00000029;
  border: 1px solid #cbcbcb;
}
.Card-box1:hover {
  cursor: pointer;
  border: 1px solid #ffa229;
}

.custom-card img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* .custom-card .card-body {
  padding: 0px;
  height: 140px;
} */

/***middle section start***/
.banner-login {
  width: 100%;
  height: 370px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  /* background-image: url(./Images/login-banner.png); */
}

.sub-menus {
  color: #dbdbdb;
  font-size: 14px;
  padding-top: 10px;
}

.sub-menus span {
  color: #ffffff;
}

/*****form area***/

.Card-box {
  box-shadow: 0px 1px 16px #00000029;
  border: 1px solid #cbcbcb;
  padding: 4rem;
}

.custom-input {
  color: #cbcbcb;
  height: 50px;
  margin-bottom: 40px;
  font-size: 14px;
}

.custom-input:focus {
  box-shadow: none;
}

.input-label {
  color: #656565;
  font-size: 14px;
}

.forgot-p {
  color: #ffa229;
}

.main-buttons {
  color: #fff;
  background: #00cc99;
  border-radius: 40px;
  width: 220px;
  height: 60px;
  border: none;
  font-size: 16px;
  margin-top: 90px;
  margin-bottom: 90px;
  font-weight: bold;
  box-shadow: 0px 3px 6px #00000029;
}

.main-buttons:hover {
  background: #02c090;
}

.textare-custom {
  color: #cbcbcb;
}

/***service area start*****/

.service-bg {
  background-color: #f2f2f2;
  padding: 70px 0px;
}

.service-heading h2 {
  color: #393939;
  font-size: 40px;
  font-weight: bold;
}

.service-border {
  width: 82px;
  height: 3px;
  background-color: #00cc99;
  margin-top: 14px;
}

.color-serve {
  color: #656565;
}

.serve-sub {
  width: 108px;
  height: 108px;
  background-color: #00cc99;
  border-radius: 50%;
}

.serve-sub i {
  font-size: 50px;
  color: #fff;
  padding: 30px 0px;
}

.border-serve {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding: 20px 0px;
}

.services-right {
  position: relative;
  width: 100%;
  height: 100%;
}

.serve-img-border {
  border: 10px solid #00cc99;
  height: 617px;
  margin-top: -12px;
  width: 372px;
  float: right;
}

.serve-img {
  position: absolute;
  top: 18px;
  right: 30px;
  width: 100%;
  height: 100%;
  max-width: 544px;
}

.display-hide {
  display: none;
}

.display-window {
  display: block;
}

/***hot jobs start****/

.hot-jobhead h4::after {
  left: calc(50% - 44px);
}

.job-card {
  box-shadow: 0px 3px 22px #00000029;
  border: 1px solid transparent;
  margin-bottom: 30px;
  border-left: 10px solid transparent;
  border-radius: 6px;
}

.job-card:hover {
  border-left: 10px solid #0869f6;
}

.job-card h5 {
  color: #3b3838;
}

.tag-bg {
  border: 1px solid #0d5dce;
  border-radius: 33px;
  background-color: #fff;
  color: #656565;
  text-align: center;
  width: auto;
  height: 37px;
  margin-right: 20px;
  padding: 0px 20px;
}

.font-16 {
  font-size: 16px;
}

.icons-b {
  margin-top: -12px;
}

.icons-l {
  margin-top: -7px;
}

.icons-i {
  margin-top: -7px;
}

.yen-color {
  color: #ffa229;
  font-size: 18px;
}

.yen-font {
  font-size: 18px;
}

.job-details {
  width: 100%;
  height: 53px;
  background-color: #a5a6a8;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #a5a6a8;
  border-radius: 35px;
  max-width: 195px;
  box-shadow: 0px 3px 6px #00000029;
}

.job-details:hover {
  background-color: #0869f6;
  color: #fff;
  border: 1px solid #0869f6;
}

/****job search start***/
.jobsearch {
  width: 100%;
  height: 472px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  /* background-image: url(./Images/job-searchbanner.png); */
}

.job-header {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 6rem;
}

.place-btn {
  background-color: #ffa229;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  max-width: 330px;
  height: 97px;
  border-radius: 50px;
  border: 4px solid #fff;
  margin-right: 3rem;
  box-shadow: 0px 3px 6px #00000029;
}

.place-btn:hover {
  background-color: #fb8f03;
}

.Occupation-btn {
  background-color: #00cc99;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  max-width: 330px;
  height: 97px;
  border-radius: 50px;
  border: 4px solid #fff;
  box-shadow: 0px 3px 6px #00000029;
}

.Occupation-btn:hover {
  background-color: #02c090;
}

.mt-custom-5 {
  margin-top: 5rem;
}

/****popup area****/
.Close-btn {
  position: absolute;
  right: 18px;
  top: 18px;
  border: none;
  background-color: transparent;
  color: #656565;
  font-size: 18px;
}

.Close-btn:hover {
  color: #00cc99;
}

.search-area {
  background-color: #f2f2f2;
  padding: 20px;
  overflow-y: scroll;
  max-height: 720px;
}

.border-none {
  border: none;
}

.popup-head {
  position: relative;
}

.popup-head h6 {
  font-size: 18px;
  font-weight: bold;
  color: #656565;
}

.popup-head h6::after {
  content: "";
  position: absolute;
  display: block;
  width: 39px;
  height: 2px;
  background: #00cc99;
  bottom: 0;
}

.popup-head h6::before {
  content: "";
  display: block;
}

.form-chck {
  width: 20px;
  height: 20px;
  margin-top: 0px;
  border: 1px solid #cbcbcb;
}

.cancel-btn {
  color: #fff;
  background: #a5a6a8;
  border-radius: 40px;
  width: 220px;
  height: 60px;
  border: none;
  font-size: 16px;
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: bold;
  margin-right: 40px;
  box-shadow: 0px 3px 6px #00000029;
}

.cancel-btn:hover {
  background: #9c9d9e;
}

.sub-btn {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mt-60 {
  margin: 40px 0px;
}

/****counseling section start***/
.margin-img {
  margin-top: 15rem;
}

.counseling-section {
  background-color: #636363;
  padding: 0px 80px;
  height: 437px;
  margin-bottom: 15rem;
}

.counsel-border {
  border: 5px solid #00cc99;
  height: 370px;
  margin-top: 0px;
  width: 100%;
  max-width: 630px;
  margin-left: 6rem;
}

.counsel-relative {
  position: relative;
}

.counsel-img {
  position: absolute;
  top: -120px;
  left: 0px;
  width: 100%;
  height: 468px;
  max-width: 702px;
}

.counseling-btn2 {
  width: auto;
  font-size: 25px;
  border-radius: 57px;
  height: 78px;
  color: #fff;
  background: #00cc99;
  border: 1px solid #00cc99;
  box-shadow: 0px 3px 6px #00000029;
  padding: 0px 87px;
  margin-top: 40px;
}

.counseling-btn2:hover {
  background: #02c090;
  border: 1px solid #00cc99;
}

.counsel-hide {
  display: block;
}

/******blog start******/
.blog-whitebg {
  position: relative;
}

.blog-heading {
  width: 90%;
  height: 50px;
  background-color: #fff;
  position: absolute;
  bottom: -2px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  left: 50%;
  transform: translate(-50%, -0%);
}

.Card-blog {
  box-shadow: 0px 3px 18px #00000029;
  border: 1px solid #cbcbcb;
}

/* .Card-blog:hover {
  cursor: pointer;
  border: 1px solid #ffa229;
} */

.blog-head {
  font-size: 22px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.p-left {
  padding: 0px 60px;
}

.read-more {
  color: #888888;
  text-decoration: none;
}

.slide-btn {
  position: relative;
}

.button-slide {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  color: #c6c6c7;
  top: -321px;
  opacity: 9;
  font-size: 30px;
}

.button-slide:hover {
  color: #fff;
  background-color: #ffa229;
  opacity: 9;
}

/****pickup categories*****/

.category-btn {
  background-color: #fff;
  color: #656565;
  font-size: 18px;
  font-weight: 600;
  border: none;
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
  height: 120px;
  border-radius: 30px;
}

.category-btn:hover {
  background-color: #0869f6;
  color: #fff;
}

/***link-banner***/
.link-banner {
  position: relative;
}

.link-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #0869f6;
  padding: 15px;
  opacity: 0.8;
  width: 90%;
}

.link-bg::after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-top: 72px solid #0869f6;
  border-right: 18px solid transparent;
  right: -18px;
  top: 0;
}

.link-bg::before {
  content: "";
  display: block;
}

.link-bg p {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.Card-link {
  border: 1px solid #cbcbcb;
}

.Card-link:hover {
  cursor: pointer;
  border: 1px solid #0869f6;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
}

/******job search page****/
.banner-search {
  width: 100%;
  height: 472px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  /* background-image: url(./Images/job-searchbanner.png); */
}

.Card-box-search {
  box-shadow: 0px 3px 6px #00000029;
  background-color: #fff;
}

.search-area2 {
  background-color: #f2f2f2;
  padding: 30px;
}

.heading-searching {
  position: relative;
  padding: 20px;
  margin-left: 20px;
}

.heading-searching::after {
  content: "";
  position: absolute;
  display: block;
  background-color: #00cc99;
  left: 0;
  top: 16px;
  width: 18px;
  height: 44px;
  border-radius: 6px;
}

.heading-searching h5 {
  font-size: 25px;
  font-weight: bold;
  color: #393939;
  padding-left: 10px;
}

.card-search-custom {
  max-height: 800px;
  overflow-y: scroll;
}

/*****job list****/
.Card-box-left {
  box-shadow: 0px 3px 6px #00000029;
  background-color: #fff;
  border-radius: 6px;
}

.left-top {
  background-color: #a5a6a8;
  padding: 20px 0px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.left-top h6 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.search-list {
  padding: 30px 10px;
}

.search-list-icons {
  font-size: 22px;
  color: #888888;
  padding-right: 15px;
}

.edit-icon {
  color: #0869f6;
  font-size: 16px;
}

.sub-search {
  color: #656565;
  padding-top: 15px;
  padding-left: 33px;
}
.sub-search span {
  padding-right: 5px;
}
.sub-search span::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 12px;
  background-color: #00cc99;
  top: 0;
  bottom: 0;
  margin-right: 2px;
}

.search-box {
  height: 50px;
  color: #fff;
  background-color: #00cc99;
  padding: 0px 20px;
  cursor: pointer;
}

.job-list-card {
  /* box-shadow: 0px 3px 22px #00000029;
  border: 1px solid transparent;
  margin-bottom: 30px;
  border-radius: 6px; */
  background-color: #fff;
  box-shadow: 0px 0px 10px #00000029;
}

.job-list-card h5 {
  color: #3b3838;
}

.page-list {
  padding: 0px 3px;
}

.page-btn {
  border-radius: 30px !important;
  width: 37px;
  height: 37px;
  font-size: 16px;
  background-color: #e9e9e9;
  color: #00cc99;
  border: none;
  text-align: center;
}

.page-btn:hover {
  color: #fff;
  background-color: #00cc99;
}

.page-num {
  border: none;
  color: #393939;
  font-size: 16px;
  border-radius: 30px !important;
  width: 37px;
  height: 37px;
  text-align: center;
}

.page-num:hover {
  color: #fff;
  background-color: #00cc99;
}

.sub-text {
  color: #dbdbdb;
  font-size: 14px;
  padding-top: 10px;
}

.sub-text span {
  color: #3e3e3e;
}

/***job details page***/
.details-section .table {
  border-collapse: separate;
  border-spacing: 6px;
}

.details-head {
  background-color: #f3f3f3 !important;
  color: #393939;
  font-weight: bold;
  border-bottom-width: 0px !important;
  width: 25%;
  padding: 15px !important;
}

.details-p {
  color: #656565;
  border: 1px solid #f3f3f3 !important;
  border-bottom-width: 1px !important;
  padding: 15px !important;
}

.apply-btn {
  width: 100%;
  height: 53px;
  background-color: #00cc99;
  border: 1px solid #00cc99;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 35px;
  max-width: 195px;
  box-shadow: 0px 3px 6px #00000029;
}

.apply-btn:hover {
  background-color: #02c090;
  color: #fff;
  border: 1px solid #02c090;
}

.comment-border {
  width: 96px;
  height: 96px;
  border: 1px solid #3b3838;
  border-radius: 50%;
  text-align: center;
}

.comment-border img {
  width: 95px;
  height: 95px;
  border-radius: 50%;
}

.comment-bg {
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 6px;
}

.col2 {
  width: 12%;
}

.col10 {
  width: 84%;
}

/*****company list****/

.company-logo {
  width: 122px;
  height: 122px;
  border-radius: 50%;
  border: 1px solid #d2d2d2;
  text-align: center;
}

.company-logo img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.colmd-2 {
  width: 12%;
}

.colmd-10 {
  width: 87%;
}

.tb-90 {
  padding-top: 20rem;
  padding-bottom: 20rem;
}

.reg-btn {
  color: #fff;
  border-radius: 40px;
  width: 220px;
  height: 60px;
  border: none;
  font-size: 16px;
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: bold;
  margin-right: 40px;
  box-shadow: 0px 3px 6px #00000029;
  background: #ffa229;
  border: 1px solid #ffa229;
}

.reg-btn:hover {
  color: #ffa229;
  background: #fff;
  border: 1px solid #ffa229;
}
/***faq***/
.accor-bg {
  background-color: #f5f5f5;
  border: transparent;
  border-radius: 6px !important;
  color: #656565;
  font-size: 16px;
  font-weight: bold;
  height: 90px;
}
.accor-body {
  background-color: #fff;
  border-color: #cbcbcb;
  border-radius: 6px !important;
}
.accor-bg:not(.collapsed) {
  background-color: #f5f5f5;
  border: transparent;
  color: #656565;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.accor-bg:focus {
  border-color: none;
  outline: 0;
  box-shadow: none;
}
.accor-bg::after {
  /* background-image: url(./Images/chevron-left-solid.png); */
  margin-top: 15px;
}
.accor-bg:not(.collapsed)::after {
  /* background-image: url(./Images/chevron-left-solid.png); */
  margin-top: -6px;
}
.accordion-custom:not(:first-of-type) {
  border-top: 1 !important;
  border-top: 1px solid #cbcbcb;
}

/***terms of use start and privacy policy***/
.terms-privacy {
  position: relative;
  padding: 20px;
}

.terms-privacy::after {
  content: "";
  position: absolute;
  display: block;
  background-color: #00cc99;
  left: 0;
  top: 16px;
  width: 18px;
  height: 44px;
  border-radius: 6px;
}

.terms-privacy h5 {
  font-size: 16px;
  font-weight: bold;
  color: #393939;
  padding-left: 10px;
  padding-top: 8px;
}

/***blog section start***/

.blog-img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.blog-body {
  flex: 1 1 auto;
  padding: 0.7rem 1.25rem;
}

/****service intro**/
.services-page-right {
  position: relative;
  width: 100%;
  height: 100%;
}

.serve-page-border {
  border: 8px solid #00cc99;
  height: 597px;
  margin-top: -74px;
  width: 83%;
  max-width: 734px;
  margin-left: 6rem;
}

.serve-page-img {
  position: absolute;
  top: 34px;
  left: 0px;
  width: 94%;
  height: 612px;
  max-width: 788px;
}
.pe-col6 {
  padding-left: 0px;
}
.banner-login {
  background-position: center right;
}
.card-serv {
  box-shadow: 0px 3px 16px #00000029;
}
.cd-icon {
  position: relative;
}
.serve-sub1 {
  width: 108px;
  height: 108px;
  background-color: #00cc99;
  border-radius: 50%;
  position: absolute;
  top: -53px;
}

.serve-sub1 i {
  font-size: 50px;
  color: #fff;
  padding: 30px 0px;
}
.card-cd {
  padding-top: 5rem;
  padding-bottom: 2rem;
}
.number-bg {
  background-color: #ffa229;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  text-align: center;
  padding-top: 2.7rem;
}
.number-bg span {
  font-size: 40px;
  color: #fff;
  font-weight: bold;
}

/***media query start***/

@media only screen and (max-width: 769px) {
  .border-bottom1 {
    border-bottom: 1px solid #707070;
    display: block;
  }

  .display-hide {
    display: block;
  }

  .display-window {
    display: none;
  }

  .mt-35 {
    margin-top: 35px;
  }

  .dy-flex {
    display: flex !important;
  }

  .place-btn {
    margin-right: 2rem;
    width: 230px;
  }

  .Occupation-btn {
    width: 230px;
  }

  .cancel-btn {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .reg-btn {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .sub-btn {
    margin-top: 0px;
  }

  .hide-blog {
    display: none;
  }

  .col-blog6 {
    width: 100%;
  }

  .link-bg {
    height: 100px;
  }

  .link-bg::after {
    border-top: 100px solid #0869f6;
    border-right: 15px solid transparent;
    right: -14px;
  }

  .details-head {
    width: 100%;
    display: block;
  }

  .details-p {
    width: 100%;
    display: block;
  }

  .col2 {
    width: 100%;
    text-align: center;
    margin-bottom: 10px !important;
  }

  .col10 {
    width: 100%;
  }

  .colmd-2 {
    width: 28% !important;
  }

  .colmd-10 {
    width: 72% !important;
  }
  .serve-page-img {
    top: 21px;
    left: 10px;
    width: 100%;
    height: auto;
    max-width: 304px;
  }
  .serve-page-border {
    border: 8px solid #00cc99;
    height: 224px !important;
    margin-top: -74px;
    width: 237px;
    max-width: 715px;
    margin-left: 6rem;
  }
  .pe-col6 {
    padding-left: 30px;
  }
  .mb-6 {
    margin-bottom: 5rem !important;
  }
  .number-bg {
    margin-bottom: 15px;
  }
  .banner-login {
    background-position: center right;
  }
  .article-buttons {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }
  /* .article-heading {
    width:100% !important;
  } */
  .display-table-cell {
    display: table-cell !important;
  }
  .blog-img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .footer-logo {
    margin: 0px !important;
  }
  .footer-plogo {
    margin: 0px !important;
  }
  .footer-pa-logo {
    padding: 8px 26px !important;
  }
  .button-display-none-up {
    display: none !important;
  }
  .button-display-none-down {
    display: inline !important;
  }
  /* .p-leftt {
    padding: 0px 57px !important;
} */
}

@media only screen and (max-width: 575px) {
  .Card-box {
    padding: 1rem;
  }

  .banner-top h3 {
    padding-top: 2rem;
  }

  .serve-img {
    position: absolute;
    top: 18px;
    right: 21px;
    width: 97%;
    height: 100%;
    max-width: 319px;
  }

  .serve-img-border {
    border: 7px solid #00cc99;
    background-color: #636363;
    height: 350px;
    margin-top: -3px;
    width: 224px;
    float: right;
  }

  .job-col2 {
    width: 100% !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .place-btn {
    margin-right: 0rem;
    width: 100%;
    margin-bottom: 2rem;
    height: 70px;
  }

  .Occupation-btn {
    width: 100%;
    height: 70px;
  }

  .job-header {
    padding-top: 15px;
  }

  .mt-custom-5 {
    margin-top: 1rem;
  }

  .p-left {
    padding: 0px 40px;
  }

  .colmd-2 {
    width: 100% !important;
  }

  .colmd-10 {
    width: 100% !important;
  }
  h3 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 348px) {
  .border-green {
    top: 11rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1510px) {
  .custom-col10 {
    flex: 0 0 auto !important;
    width: 58.333333% !important;
  }

  .link-bg::after {
    border-top: 98px solid #0869f6;
    border-right: 18px solid transparent;
    right: -16px;
  }

  .col2 {
    width: 15%;
  }

  .col10 {
    width: 81%;
  }

  .col-apply6 {
    width: 100%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
  .col2 {
    width: 39%;
  }

  .col10 {
    width: 60%;
  }

  .colmd-2 {
    width: 20% !important;
  }

  .colmd-10 {
    width: 80% !important;
  }
  .pe-col6 {
    padding-left: 40px;
  }
  .banner-login {
    background-position: center right;
  }
  .col-article-3 {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 2rem;
  }
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
  .padding-body {
    padding-left: 30px;
    padding-right: 30px;
  }

  .nav-w {
    width: 100%;
    margin-left: 0px !important;
  }

  .login-btn {
    margin-bottom: 20px;
    width: 100%;
  }

  .counseling-btn {
    width: 100%;
  }

  .dropdown-toggle::after {
    float: right;
  }

  .border-none {
    border-color: transparent !important;
  }

  .border-none:focus {
    outline: none !important;
    border: none;
    box-shadow: none;
    color: #fb8f03;
  }

  .display-none {
    display: none;
  }

  .icons-btm {
    margin-bottom: 15px;
  }

  .job-col2 {
    width: 50%;
  }

  .job-details {
    margin-top: 20px;
  }

  .apply-btn {
    margin-top: 20px;
  }

  .tag-bg {
    margin-bottom: 15px;
  }

  .col-custom10 {
    width: 100%;
  }

  .col-custom-2 {
    width: 100%;
  }

  .counsel-hide {
    display: none;
  }

  .col-custom-6 {
    width: 100%;
  }

  .counseling-section {
    padding: 0px 30px;
    height: auto;
    margin-bottom: 0px !important;
  }

  .counseling-btn2 {
    width: auto;
    border-radius: 85px;
    height: auto;
    padding: 13px 69px;
    margin-bottom: 33px;
  }

  .custom-col3 {
    width: 100%;
  }

  .custom-col9 {
    width: 100%;
  }
  .col-md6 {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1195px) {
  .login-btn {
    width: 170px;
  }

  .counseling-btn {
    width: 236px;
  }

  .padding-body {
    padding-left: 50px;
    padding-right: 50px;
  }

  .icons-btm {
    margin-bottom: 15px;
  }

  .job-col2 {
    width: 50%;
  }

  .job-details {
    margin-top: 20px;
  }

  .apply-btn {
    margin-top: 20px;
  }

  .tag-bg {
    margin-bottom: 15px;
  }

  .col-custom10 {
    width: 100%;
  }

  .col-custom-2 {
    width: 100%;
  }
}

@media only screen and (min-width: 1196px) and (max-width: 1452px) {
  .job-col2 {
    width: 22%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1280px) {
  .custom-col3 {
    width: 30%;
  }

  .custom-col9 {
    width: 70%;
  }

  .col2 {
    width: 18%;
  }

  .col10 {
    width: 64%;
  }

  .colmd-2 {
    width: 24% !important;
  }

  .colmd-10 {
    width: 76% !important;
  }
  .col-article-3 {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 1281px) and (max-width: 1400px) {
  .col-article-3 {
    flex: 0 0 auto;
    width: 34%;
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1600px) {
  .counsel-img {
    width: 100%;
    height: 405px;
    max-width: 702px;
  }

  .counsel-border {
    border: 5px solid #00cc99;
    height: 309px;
    margin-top: 0px;
    width: 100%;
    max-width: 630px;
    margin-left: -1.5rem;
  }

  .link-bg::after {
    border-top: 98px solid #0869f6;
    border-right: 18px solid transparent;
    right: -16px;
  }

  .colmd-2 {
    width: 16%;
  }

  .colmd-10 {
    width: 84%;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 637px;
    margin: 1.75rem auto;
  }
}
@media only screen and (min-width: 360px) and (max-width: 768px) {
  .custom-md4 {
    width: 34%;
  }
  .custom-md8 {
    width: 66%;
  }
}

/*********new css*********/
.privacy-backg {
  background-color: #f2f2f2;
  width: 100%;
  padding: 30px;
}
.table-privacy > :not(caption) > * > * {
  padding: 1rem 1rem !important;
  color: #656565;
  font-size: 14px;
}
.table-privacy th {
  color: #393939;
  font-size: 16px;
}

.success-i {
  color: #00cc99;
  font-size: 100px;
}

/***article css***/
.custom-search {
  height: 40px;
  border-radius: 20px;
  max-width: 400px !important;
  font-size: 14px;
  width: 400px !important;
}
.custom-search1 {
  height: 40px;
  border-radius: 20px;
  max-width: 400px !important;
  font-size: 14px;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}
.fnt-25 {
  font-size: 25px;
}
.list-custom {
  padding: 5px;
}
.list-custom:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 14px;
  height: 14px;
  padding: 0;
  border-radius: 50%;
}
.article-slide {
  font-size: 45px;
  font-weight: bold;
  color: #fff;
  padding-top: 0px !important;
}
.slide-top {
  padding-top: 5rem;
}
.sub-text2 {
  font-size: 16px;
  color: #dbdbdb;
}
.sub-text2 span {
  color: #fff;
}
.Trending-btn {
  color: #fff;
  background-color: #00cc99;
  border: 1px solid #fff;
  border-radius: 6px;
  height: 35px;
  width: 100px;
  font-size: 14px;
  /* cursor: text !important; */
}
.time-class {
  font-size: 16px;
  color: #fff;
  text-decoration: underline;
}
.detailss-btn {
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 6px;
  height: 35px;
  width: 100px;
  font-size: 14px;
}
.article-heading {
  color: #393939;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.article-heading h4::after {
  content: "";
  position: absolute;
  display: block;
  width: 82px;
  height: 3px;
  background: #00cc99;
  bottom: -6px;
}

.article-heading h4::before {
  content: "";
  display: block;
}
.article-buttons {
  color: #fff;
  background: #005ffa;
  border-radius: 40px;
  width: 100%;
  height: 45px;
  border: none;
  font-size: 16px;
  max-width: 200px;
}

.article-buttons:hover {
  background: #02c090;
}
.p-leftt {
  padding: 0px 65px;
}
.article-side {
  width: 100%;
  height: 200px;
}
.article-side img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.article-img-top {
  width: 100%;
  height: 270px;
  object-fit: cover;
}
.select-point {
  cursor: pointer;
  max-height: 240px;
  overflow: hidden;
}

.article-small img {
  width: 100%;
  height: 90px;
  object-fit: cover;
}
.font-14 {
  font-size: 14px;
  margin-bottom: 0px;
}
.small-h3 {
  padding-top: 5px;
  display: inline-block;
  text-overflow: ellipsis;
}
.border-bottom-article {
  border-bottom: 1px solid #ccc;
}
.blog-head1 {
  font-size: 22px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.p-leftt-article {
  padding: 0px 45px;
}
@media only screen and (max-width: 992px) {
  .hide-search {
    display: none;
  }
}

.table-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .table-text {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 223px;
      line-break: after-white-space;
    }
  }
}

.heading-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.article-heading-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.article-description-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.category-heading-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.category-description-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.table-view {
  display: table;
}
.card-table-view {
  display: table-cell;
}
.footer-card {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: transparent;
  border: none;
  margin-bottom: 10px;
}
.select-row {
  min-height: 250px;
  overflow: hidden;
}
.p-card {
  height: 41px;
  max-height: 250px;
  overflow-y: hidden;
}
.side-bg {
  background: rgb(0, 95, 250);
  background: linear-gradient(
    90deg,
    rgba(0, 95, 250, 1) 0%,
    rgba(0, 95, 250, 1) 26%,
    rgba(0, 95, 250, 0) 51%
  );
  height: inherit;
}
.carousel .control-dots .dot {
  width: 15px !important;
  height: 15px !important;
}
.footer-logo {
  width: 150px;
  height: 150px;
  background-color: #f5f4f4;
  border-radius: 6px;
  padding: 10px;
}
.footer-logo img {
  object-fit: cover;
  width: 100%;
}
.footer-colr-plogo {
  color: #fff8f8;
}
.logo-header {
  text-decoration: none;
  color: #212529;
}
.logo-header:hover {
  color: #212529;
}

/*screen zoom on iphone or safari  side menu focus ,to slove that one*/

@media screen and (max-width: 767px) {
  select:active,
  input:active,
  textarea:active {
    font-size: 16px !important;
  }
}
.button-display-none-up {
  display: inline;
}
.button-display-none-down {
  display: none;
}
.break-line {
  word-break: break-word;
  white-space: pre-wrap;
}
@media (max-width: 336px) {
  .Trending-btn {
    font-size: 12px !important;
  }
}
.blog-content {
  width: 90%;
  background-color: #fff;
  position: inherit;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  left: 50%;
  transform: translate(-50%, -0%);
  padding-bottom: 30px !important;
}
.error-page {
  width: 100%;
  height: 100vh;
  background-color: #edeeef;
}
.error-middle {
  display: flex;
  align-items: center;
}
.error-page-wd {
  width: 100%;
  max-width: 520px;
  padding: 15px;
  margin: auto;
}
.er-text {
  color: #004fc1;
  font-size: 60px;
  font-weight: bold;
}
.fnt-14-v {
  font-size: 14px !important;
  font-weight: bold;
  font-family: "Noto Sans CJK JP bold";
}
.heading-text-popular {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* Styles for react-markdown */

blockquote {
  color: #666;
  margin: 0;
  padding-left: 3em;
  border-left: 0.5em #eee solid;
  word-break: break-all !important;
}
pre code {
  word-break: break-all !important;
}
p {
  word-break: break-all !important;
}

.react-markdown-detail img {
  max-width: 100%;
  height: auto;
  aspect-ratio: attr(width) / attr(height);
}
.react-markdown-detail iframe {
  max-width: 100%;
  min-height: 357px;
  height: 100%;
}
.react-markdown-test pre code,
.md:not(.use-csslab) pre code {
  white-space: pre-wrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.top-paragrm .react-markdown-carousel p {
  color: #ffffff !important;
}
.caroual-p-card {
  overflow-y: auto;
  height: 95px;
}

.caroual-p-card::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.caroual-p-card {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.react-markdown-detail table {
  width: 100%;
  table-layout: fixed;
  border: 1px solid rgb(102, 102, 135);
}
.react-markdown-detail table td {
  padding: 8px;
  border: 1px solid rgb(102, 102, 135);
}

.blog_list_heading {
  color: #000;
  font-family: Noto Sans CJK JP;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/*Home Page End*/
pre code {
  white-space: nowrap;
}
span,
p {
  word-break: break-all !important;
}
strong {
  word-break: break-all !important;
}
.react-markdown-detail h6,
.react-markdown-test h6,
.react-markdown-carousel h6 {
  font-size: 0.83em;
  font-weight: normal;
  font-family: "Noto Sans CJK JP";
  word-break: break-all !important;
}
.react-markdown-detail h5,
.react-markdown-test h5,
.react-markdown-carousel h5 {
  font-size: 1em;
  font-weight: normal;
  font-family: "Noto Sans CJK JP";
  word-break: break-all !important;
}
.react-markdown-detail h4,
.react-markdown-test h4,
.react-markdown-carousel h4 {
  font-size: 1.25em;
  font-weight: normal;
  font-family: "Noto Sans CJK JP";
  word-break: break-all !important;
}
.react-markdown-detail h3,
.react-markdown-test h3,
.react-markdown-carousel h3 {
  font-size: 1.5em;
  font-weight: normal;
  font-family: "Noto Sans CJK JP";
  word-break: break-all !important;
  padding-top: 0 !important;
}
.react-markdown-detail h2,
.react-markdown-test h2,
.react-markdown-carousel h2 {
  font-size: 1.75em;
  word-break: break-all !important;
}
.react-markdown-detail h1,
.react-markdown-test h1,
.react-markdown-carousel h1 {
  font-size: 2.5em;
  word-break: break-all !important;
}
.pbcarousel-5 {
  padding-bottom: 6rem;
}
.top-paragrm .react-markdown-carousel span {
  color: #ffffff !important;
}



.card-menu {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 40%;
}

.font-30 {
  font-size: 30px;
}

.bread-link li a {
  color: #806731;
  text-decoration: none;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding: 0 0.5rem 0 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "⦁");
}

.bottom-link li a {
  color: #6c757d;
  padding: 8px;
}

.bottom-link li a:hover {
  color: #6c757d;
}

.tag-icon {
  padding-right: 2px;
  font-size: 12px;
}

.date-time {
  font-size: 16px;
  color: #6c757d;
}

.date-time-icon {
  font-size: 12px;
  color: #6c757d;
}



.img-div {
  display: inline-block;
  overflow: hidden;
}

.img-div img {
  display: block;
  transition: transform 0.8s;
  width: 100%;
}

.img-div:hover img {
  transform: scale(1.1);
  transform-origin: 50% 50%;
}

.article-hd {
  font-size: 22px;
  position: relative;
  padding-left: 32px;
}

.article-hd span {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 3px;
  background-color: #00cc99;
}

.bg-article {
  background-color: #fffdea;
  padding: 10px;
}

.right-banner {
  display: inline-block;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 2px 6px #00000029;
}

.right-banner img {
  display: block;
  transition: transform 0.8s;
  width: 100%;
}

.right-banner:hover img {
  transform: scale(1.1);
  transform-origin: 50% 50%;
}

.right-white {
  box-shadow: 0px 0px 7px #00000029;
}

.right-white:hover {
  background-color: #fff;
  box-shadow: 0px 0px 10px #00000029;
  opacity: 0.8;
}

.right-white-img {
  display: inline-block;
  overflow: hidden;
}

.right-white-img img {
  height: 200px;
  display: block;
  transition: transform 0.8s;
}

.right-white-img:hover img {
  transform: scale(1.1);
  transform-origin: 50% 50%;
}

.bottom-white-img {
  display: inline-block;
  overflow: hidden;
  border-radius: 6px;
}

.bottom-white-img img {
  height: 200px;
  display: block;
  transition: transform 0.8s;
}

.bottom-white-img:hover img {
  transform: scale(1.1);
  transform-origin: 50% 50%;
}

.right-heading {
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 95%;
}

.right-heading a {
  text-decoration: none;
  color: #000;
}

.right-heading a:hover {
  color: #0869f6;
}

.card-tag li a {
  color: #ffa229;
  text-decoration: none;
}

.card-tag li a {
  color: #ffa229;
  padding: 8px;
}

.card-tag li a:hover {
  color: #ffa229;
}

.date-time-card {
  font-size: 12px;
  color: #6c757d;
}

.date-card-icon {
  font-size: 14px;
  color: #6c757d;
}

.job-list-card {
  background-color: #fff;
  box-shadow: 0px 0px 10px #00000029;
}

.border-bottom-article {
  border-bottom: 1px solid #ccc;
}

/***footer css***/
.footer-bg {
  background: #333333;
  width: 100%;
  height: auto;
  position: static;
  bottom: 0;
  left: 0;
  margin: auto;
}

.footer-logo {
  background-color: #f5f4f4;
  border-radius: 6px;
  height: 150px;
  padding: 10px;
  width: 150px;
}

.footer-logo img {
  object-fit: cover;
  width: 100%;
}

.footer-colr-plogo {
  color: #fff8f8;
}

/******footer start****/
.footer-bg {
  background: #333333;
  width: 100%;
  height: auto;
  position: static;
  bottom: 0;
  left: 0;
  margin: auto;
}

.nav-footer {
  color: #fff8f8;
}

.nav-footer:hover {
  color: #00cc99;
}

/****Banner****/
.bottom-banner {
  display: inline-block;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 2px 6px #00000029;
  height: 300px;
  width: 100%;
}

.bottom-banner img {
  display: block;
  width: 100%;
  height: 300px;
}
.bottom-banner:hover img {
  cursor: pointer;
}
.bootom-right-bg {
  background-color: #636363;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.inquiry-btn {
  color: #fff;
  background: #00cc99;
  border-radius: 40px;
  width: 150px;
  height: 50px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0px 3px 6px #00000029;
}
.inquiry-btn:hover {
  background: #02c090;
}
.inquiry-hd {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}
.inquiry-p {
  font-size: 16px;
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-sm3 {
    width: 50%;
  }

  .main-hd {
    width: 100%;
    justify-content: center;
  }
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
  .padding-body {
    padding-left: 30px;
    padding-right: 30px;
  }

  .display-none {
    display: none;
  }

  .custom-col3 {
    width: 100%;
  }

  .custom-col9 {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1195px) {
  .padding-body {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1280px) {
  .custom-col3 {
    width: 30%;
  }

  .custom-col9 {
    width: 70%;
  }
}

.link-hover:hover {
  color: #0074d9; /* Change to the desired text color on hover */
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
